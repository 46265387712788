import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Link } from 'gatsby'
import { TimelineMax } from 'gsap'
import { isMobile } from 'react-device-detect'
import { applyTheme } from '../../utils/theme'

const TransitionOUT = ({ exit: { length, state }, node }) => {
  if (state.theme) {
    applyTheme(state.theme)
  }

  let scrollTop
  if (document.scrollingElement && document.scrollingElement.scrollTop) {
    scrollTop = document.scrollingElement.scrollTop
  } else {
    scrollTop = window.pageYOffset
  }

  const target = node.querySelector('.js-transition-link-target')
  new TimelineMax()
    .set(node, {
      overflowY: 'hidden',
      height: '100vh',
      scrollTop: scrollTop,
    })
    .fromTo(
      target,
      length,
      {
        y: 0,
        opacity: 1,
      },
      {
        y: '40vh',
        opacity: 0,
      }
    )
}

const TransitionIN = ({ entry: { length }, node }) => {
  const target = node.querySelector('.js-transition-link-target')
  new TimelineMax().fromTo(
    target,
    length,
    {
      y: '40vh',
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
    }
  )
}

const TransitionLinkOutInFade = props => {
  return isMobile ? (
    <Link className={props.className} to={props.to}>
      {props.children}
    </Link>
  ) : (
    <TransitionLink
      {...props}
      exit={{
        ...props.exit,
        length: 0.5,
        zIndex: 999,
        trigger: ({ exit, node }) => TransitionOUT({ exit, node }),
      }}
      entry={{
        ...props.entry,
        length: 0.5,
        delay: 0.5,
        trigger: ({ entry, node }) => TransitionIN({ entry, node }),
      }}
    >
      {props.children}
    </TransitionLink>
  )
}

export default TransitionLinkOutInFade
