import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import removeSpaces from '../../utils/removeSpaces'

class Footer extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            prismicGlobal {
              data {
                email
                instagram_link
                location_footer {
                  html
                }
                title
                phone_footer
                phone_label_footer
              }
            }
          }
        `}
        render={data => (
          <div className="text-sm 2xl:text-base pt-35 md:pt-40 pb-10 md:pb-12 lg:pb-16 px-gs">
            <div className="flex flex-wrap -ml-gs">
              <div className="pl-gs w-full md:w-1/2 mb-10 md:mb-0">
                {data.prismicGlobal.data.email && (
                  <a
                    className="link"
                    href={`mailto:${data.prismicGlobal.data.email}`}
                  >
                    {data.prismicGlobal.data.email}
                  </a>
                )}
              </div>
              <div className="pl-gs w-1/2 md:w-1/4">
                {data.prismicGlobal.data.location_footer.html && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.prismicGlobal.data.location_footer.html,
                    }}
                  ></div>
                )}
              </div>
              <div className="pl-gs w-1/2 md:w-1/4">
                {data.prismicGlobal.data.phone_footer && (
                  <div>
                    {data.prismicGlobal.data.phone_label_footer && (
                      <span>
                        {data.prismicGlobal.data.phone_label_footer}:{' '}
                      </span>
                    )}
                    <a
                      className="link"
                      href={`tel:${removeSpaces(
                        data.prismicGlobal.data.phone_footer
                      )}`}
                    >
                      {data.prismicGlobal.data.phone_footer}
                    </a>
                  </div>
                )}
                {data.prismicGlobal.data.instagram_link && (
                  <div>
                    Social:{' '}
                    <a
                      className="link"
                      href={data.prismicGlobal.data.instagram_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

Footer.defaultProps = {
  isHidden: false,
}

export default Footer
