import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import removeSpaces from '../utils/removeSpaces'
import Layout from '../components/Layout'
import HeaderPrimary from '../components/Header/HeaderPrimary'
import Footer from '../components/Footer'
import { applyTheme, palette } from '../utils/theme'

class ContactPage extends React.Component {
  componentDidMount = () => {
    applyTheme(palette.light)
  }

  render() {
    const { location, data } = this.props
    const pageData = data.prismicContact.data
    const globalData = data.prismicGlobal.data
    return (
      <Layout location={location}>
        <SEO
          title={pageData.meta_title || 'Contact'}
          description={pageData.meta_description || ''}
          location={location}
        />
        <HeaderPrimary />
        <div className="pt-60vh">
          <div className="js-scroll-target js-transition-link-target">
            <div className="px-gs">
              <div className="flex flex-wrap justify-end md:justify-between mb-10 lg:mb-30 -ml-gs">
                <div className="pl-gs w-full md:w-8/12 mb-20 md:mb-0">
                  {pageData.content.html && (
                    <div
                      className="rte text-xl md:text-3xl 2xl:text-4xl"
                      dangerouslySetInnerHTML={{
                        __html: pageData.content.html,
                      }}
                    ></div>
                  )}
                </div>
                <div className="pl-gs w-6/12 md:w-3/12">
                  <div className="text-sm 2xl:text-base">
                    {globalData.location.html && (
                      <div className="mb-4">
                        <div className="mb-4">McCarthy</div>
                        <div
                          className="rte"
                          dangerouslySetInnerHTML={{
                            __html: globalData.location.html,
                          }}
                        ></div>
                      </div>
                    )}
                    {globalData.location.html && (
                      <div className="mb-4">
                        <a
                          className="link"
                          href={pageData.map_link && pageData.map_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            className="inline-block h-2 w-2 2xl:h-3 2xl:w-3 mr-2"
                            viewBox="0 0 12 12"
                          >
                            <path d="M2.236 0v1.62L9.27 1.6 0 10.872 1.128 12l9.293-9.272-.043 7.037H12V.021L11.978 0z" />
                          </svg>
                          <span className="leading-none">View on map</span>
                        </a>
                      </div>
                    )}
                    {globalData.email && (
                      <div>
                        <a className="link" href={`mailto:${globalData.email}`}>
                          {globalData.email}
                        </a>
                      </div>
                    )}
                    {globalData.phone_numbers
                      .filter(
                        item => item.phone !== null && item.phone_label !== null
                      )
                      .map((item, index) => {
                        return (
                          <div key={index}>
                            {item.phone_label && (
                              <span>{item.phone_label}: </span>
                            )}
                            <a
                              className="link"
                              href={`tel:${removeSpaces(item.phone)}`}
                            >
                              {item.phone}
                            </a>
                          </div>
                        )
                      })}
                    {globalData.instagram_link && (
                      <div>
                        Social:{' '}
                        <a
                          className="link"
                          href={globalData.instagram_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Instagram
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-between text-sm 2xl:text-base -ml-gs">
                <div className="pl-gs w-full md:w-8/12 mb-12 md:mb-0">
                  {pageData.image.fluid && (
                    <div className="pt-3/2 relative">
                      <div className="absolute inset-0">
                        <Img
                          className="w-full h-full"
                          fluid={pageData.image.fluid}
                          alt={pageData.image.alt}
                          loading="eager"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="pl-gs w-2/3 md:w-3/12">
                  {pageData.work_with_us.html && (
                    <div className="md:flex md:flex-col md:justify-end md:h-full">
                      <div className="md:mt-auto">
                        <h4 className="mb-4">Work with us</h4>
                        <div
                          className="rte"
                          dangerouslySetInnerHTML={{
                            __html: pageData.work_with_us.html,
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    )
  }
}

export const contactPageQuery = graphql`
  query {
    prismicContact {
      data {
        content {
          html
        }
        meta_description
        meta_title
        work_with_us {
          html
        }
        map_link
        image {
          alt
          fluid(
            imgixParams: { q: 100 }
            srcSetBreakpoints: [640, 768, 1024, 1280]
          ) {
            srcSetWebp
            srcWebp
            sizes
            src
            srcSet
            base64
            aspectRatio
          }
        }
      }
    }
    prismicGlobal {
      data {
        email
        instagram_link
        location {
          html
        }
        title
        phone_numbers {
          phone
          phone_label
        }
      }
    }
  }
`

ContactPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ContactPage
