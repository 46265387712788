import React, { useState, useEffect, useRef } from 'react'
import { TweenLite, TweenMax } from 'gsap'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import TransitionLinkOutInFade from '../TransitionLink/TransitionLinkOutInFade'
import { palette } from '../../utils/theme'

const MenuItem = ({ title, link, theme }) => (
  <TransitionLinkOutInFade
    className="link pointer-events-auto"
    to={link}
    activeClassName={'active-link'}
    partiallyActive={link === '/' ? false : true}
    exit={{
      state: {
        theme: theme,
      },
    }}
  >
    {title}
  </TransitionLinkOutInFade>
)

const HeaderPrimary = () => {
  const headerPrimary = useRef(null)
  const headerPrimaryNav = useRef(null)

  const { entry, exit } = useTransitionState()

  const [has_SlideIn, setHas_SlideIn] = useState(false)
  const [has_FadeOut, setHas_FadeOut] = useState(false)

  const [movementY, setMovementY] = useState(0)
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    setHas_SlideIn(entry.state.headerPrimary_SlideIn ? true : false)
    setHas_FadeOut(exit.state.headerPrimary_FadeOut ? true : false)
  }, [entry.state.headerPrimary_SlideIn, exit.state.headerPrimary_FadeOut])

  useEffect(() => {
    if (has_FadeOut) {
      TweenMax.to(headerPrimaryNav.current, 0.5, {
        opacity: 0,
      })
    }
  }, [has_FadeOut])

  useEffect(() => {
    if (has_SlideIn) {
      setTimeout(() => {
        TweenMax.to(headerPrimaryNav.current, 0.5, {
          y: 0,
          opacity: 1,
        })
      }, 500)
    }
  }, [has_SlideIn])

  const getOffsetTop = element => {
    const rect = element.getBoundingClientRect()
    return rect.top + window.pageYOffset
  }

  useEffect(() => {
    const eventTick = () => {
      let _movementY
      if (movementY >= 0 && movementY <= 100) _movementY = movementY
      if (movementY > 100) _movementY = 100
      if (movementY < 0) _movementY = 0

      let _opacity
      if (opacity <= 1 && opacity >= 0) _opacity = opacity
      if (opacity > 1) _opacity = 1
      if (opacity < 0) _opacity = 0

      TweenMax.to(headerPrimary.current, 0, {
        y: `-${_movementY}%`,
        opacity: _opacity,
      })
    }

    const eventScroll = () => {
      const target = document.querySelector('.js-scroll-target')
      if (!target) return
      let scrollY = window.scrollY
      let targetOffsetTop = getOffsetTop(target)
      let headerHeight =
        headerPrimary.current.getBoundingClientRect().height + 32
      setMovementY(
        ((scrollY - (targetOffsetTop - headerHeight)) / headerHeight) * 100
      )
      setOpacity(
        1 - (scrollY - (targetOffsetTop - headerHeight)) / (headerHeight / 2)
      )
    }

    window.addEventListener('scroll', eventScroll)
    TweenLite.ticker.addEventListener('tick', eventTick)

    return () => {
      window.removeEventListener('scroll', eventScroll)
      TweenLite.ticker.removeEventListener('tick', eventTick)
    }
  }, [movementY, opacity])

  return (
    <header
      ref={headerPrimary}
      className="fixed top-0 inset-x-0 pointer-events-none z-30"
    >
      <nav
        ref={headerPrimaryNav}
        style={{
          transform: `translate3d(0,${has_SlideIn ? `-100%` : 0},0)`,
          opacity: `${has_SlideIn ? 0 : 1}`,
        }}
        className="text-xl md:text-3xl 2xl:text-4xl py-4 md:py-5 lg:py-8 px-gs"
      >
        <div className="flex flex-wrap -ml-gs">
          <div className="pl-gs w-1/2 md:w-1/4">
            <MenuItem title="Work" link="/" theme={palette.light} />
          </div>
          <div className="pl-gs w-1/2 md:w-1/4">
            <MenuItem title="Archive" link="/archive" theme={palette.dark} />
          </div>
          <div className="pl-gs w-1/2 md:w-1/4">
            <MenuItem title="About" link="/about" theme={palette.light} />
          </div>
          <div className="pl-gs w-1/2 md:w-1/4">
            <MenuItem title="Contact" link="/contact" theme={palette.light} />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default HeaderPrimary
